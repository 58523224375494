<template>
  <chip :class="['subsales-booking-status', getStatusClass()]">
    {{ status.name }}
  </chip>
</template>

<script>
import Chip from "@/components/GlobalComponents/Chip";
import { subsalesBookingStatus as STATUS } from "@/enums";

export default {
  components: {
    Chip
  },
  mixins: [],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      STATUS
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (this.status.id) {
        // case STATUS.PAY_OTP_FEE:
        //   return "";
        // case STATUS.OTP_PROCEDURE:
        //   return "";
        // case STATUS.COMPLETED:
        //   return "";

        default:
          return "default";
      }
    }
  }
};
</script>

<style lang="scss">
.subsales-booking-status {
  &.default {
    background: linear-gradient(
      to right,
      $color-secondary,
      lighten($color-secondary, 15)
    );
    color: white;
  }
}
</style>
